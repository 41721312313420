import React from 'react';
import Navigationbar from 'components/organisms/Navigationbar/Navigationbar';
import styled, { css } from 'styled-components';
import ScrollTemplate from 'templates/ScrollTemplate';
import { media } from 'utils';

import ConsultImg from '../assets/images/consultation.jpg';

const ConsultWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10rem;
`;

const Heading = styled.h1`
  margin: 0;
  font-size: 3.2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.navyblueText};
  margin-bottom: 2rem;
`;

const ParagraphSmall = styled.p`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.navyblueText};
  max-width: 60%;
  text-align: center;
  line-height: 2.5rem;
  margin-bottom: 2rem;
`;
const ParagraphBigRed = styled.p`
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.redText};
  max-width: 60%;
  text-align: center;
  line-height: 3rem;
  margin-bottom: 2rem;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5rem 0;
  justify-content: flex-start;
  img {
    height: 750px;
  }
`;
const RightWrapper = styled.div`
  padding: 5rem 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BottomWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  margin: 2rem 0;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.lightBlueText};
  text-decoration: none;
  display: block;
  width: 15rem;
  border: none;
  cursor: pointer;

  ${media.desktop`
  width: 25rem;

  ${({ color }) =>
    color === 'red' &&
    css`
      width: 15rem;
    `}
  `}

  :hover {
    animation: changecolor 1s;
    background-color: transparent;

    @keyframes changecolor {
      from {
        background-color: ${({ theme }) => theme.lightBlueText};
      }
      to {
        background-color: transparent;
      }
    }
  }

  ${({ color }) =>
    color === 'red' &&
    css`
      width: 7rem;  
      background-color: ${({ theme }) => theme.buttonRed};
      border: 1px solid ${({ theme }) => theme.borderRed};

      :hover {
        animation: changecolor 1s;
        background-color: transparent;

        @keyframes changecolor {
      from {
        background-color: ${({ theme }) => theme.buttonRed}};
      }
      to {
        background-color: transparent;
      }
    `}
`;

const ButtonParagraph = styled.p`
  padding: 0.8rem 1rem;
  border-radius: 2rem;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  margin: 0;
  font-size: 1.4rem;

  ${media.desktop`
    padding: 1rem 2rem;
    font-size: 1.6rem;
  `}

  :hover {
    animation: changecolor 1s;
    background-color: transparent;
    color: ${({ theme }) => theme.lightBlueText};

    @keyframes changecolor {
      from {
        background-color: ${({ theme }) => theme.lightBlueText};
        color: ${({ theme }) => theme.lightBlueText};
      }
      to {
        background-color: transparent;
        color: ${({ theme }) => theme.lightBlueText};
      }
    }
  }

  ${({ color }) =>
    color === 'red' &&
    css`
      color: ${({ theme }) => theme.buttonTextRed};
      padding: 0.2rem 1rem;
      font-size: 0.8rem;
    `}
`;

const Consultation = () => {
  const handleShopLink = (e) => {
    e.preventDefault();
    window.location.href =
      '/shop#!/Szkolenie-Warsztatowe-AUTOPILOT-SZCZĘŚCIA/p/378375134/category=0';
  };
  return (
    <>
      <ScrollTemplate>
        <Navigationbar />
        <ConsultWrapper>
          <LeftWrapper>
            <img src={ConsultImg} />
          </LeftWrapper>
          <RightWrapper>
            <ParagraphSmall>
              Kavi Kozłowska <br />
              Trenerka Skutecznych Zmian
              <br /> Autorka książek
              <br /> Mentorka Świadomego Optymizmu <br />
            </ParagraphSmall>
            <ParagraphSmall>zaprasza na szkolenie warsztatowe</ParagraphSmall>
            <Heading style={{ color: 'rgba(234, 26, 26, 0.8)' }}>AUTOPILOT SZCZĘŚCIA</Heading>
            <ParagraphSmall>
              Jak czuć się lepiej i świadomie kreować szczęście w świecie turbulentnych zmian?
            </ParagraphSmall>
            <ParagraphBigRed>
              Szkolenie Ścieżki Szczęścia
              <br /> Warsztat Skutecznej Zmiany
              <br /> Praktyka Świadomego Optymizmu
            </ParagraphBigRed>
            <ParagraphSmall style={{ marginBottom: '0rem' }}>Edycja sierpień 2021</ParagraphSmall>
            <ParagraphBigRed style={{ marginBottom: '0rem' }}>
              {' '}
              22.08.2021 godz. 11:00 - 17:00
            </ParagraphBigRed>
            <ParagraphSmall style={{ marginBottom: '0rem' }}>
              {' '}
              Physio Therapy Clinic, ul. Kazachska 1, Warszawa
            </ParagraphSmall>
            <ParagraphSmall style={{ marginBottom: '0', fontSize: '1.3rem', fontWeight: '800' }}>
              wejście od ul. Herbu Szreniawa
            </ParagraphSmall>
            <Button onClick={handleShopLink}>
              <ButtonParagraph>Dołączam do szkolenia</ButtonParagraph>
            </Button>
            <ParagraphSmall>
              SKUTECZNA ZMIANA jest możliwa,
              <br /> jeśli naprawdę tego ZECHCESZ{' '}
            </ParagraphSmall>
            <ParagraphSmall>
              Do Ciebie należy PIERWSZY KROK
              <br /> uczyń mnie Twoim Wsparciem
            </ParagraphSmall>
            <ParagraphSmall style={{ marginBottom: '0', fontSize: '1.3rem' }}>
              Do zobaczenia na szkoleniu
            </ParagraphSmall>
            <ParagraphSmall style={{ marginBottom: '0', fontSize: '1.3rem' }}>
              Twoja Trenerka Skutecznych Zmian
              <br /> Kavi Kozłowska
            </ParagraphSmall>
          </RightWrapper>
        </ConsultWrapper>
        <ConsultWrapper>
          <BottomWrapper>
            <ParagraphSmall>SZKOLENIE WARSZTATOWE</ParagraphSmall>
            <Heading style={{ color: 'rgba(234, 26, 26, 0.8)' }}>AUTOPILOT SZCZĘŚCIA</Heading>
            <ParagraphSmall>jest dla Ciebie, jeśli</ParagraphSmall>
            <ParagraphSmall>
              <div style={{ textAlign: 'left' }}>
                pragniesz rozwoju i życiowej ZMIANY, ale nie wiesz jak przeprowadzić to SKUTECZNIE,
                <br />
                masz TRUDNOŚCI Z DOPASOWANIEM SIĘ DO DYNAMIKI ZMIENNEGO OTOCZENIA (zmiany na rynku
                pracy lub otoczeniu biznesowym, w życiu osobistym, pandemia),
                <br /> masz tendencję do wpadania w CIĄGI NEGATYWNYCH MYŚLI,
                <br /> zauważasz, że twój PESYMIZM, NARZEKANIE i negatywne podejście BLOKUJĄ CIĘ w
                osiąganiu maksimum twojego potencjału,
                <br /> twoje PRZEKONANIA, ZAMYKAJĄ CIĘ na podejmowanie wyzwań osobistychi
                zawodowych,
                <br />
                nie ruszasz z miejsca przez własny PERFEKCJONIZM, wciąż opóźniasz moment działania.
                Masz wiele pomysłów, które nie przechodzą z fazy projektu do fazy realizacji przez
                PROBLEM z PROKRASTYNACJĄ,
                <br /> czujesz, że BRAK ci SILNEJ WOLI przez co tkwisz w niekorzystnych nawykach lub
                kompulsywnych zachowaniach, <br />
                twój problem to brak MOTYWACJI, pomimo to pragniesz, by wreszcie „chciało ci się
                chcieć`&quot;`,
                <br /> czujesz się PRZYTŁOCZONY PRZEWLEKŁYM STRESEM.{' '}
              </div>
            </ParagraphSmall>
            <Button onClick={handleShopLink}>
              <ButtonParagraph>zajmij miejsce dla siebie już teraz</ButtonParagraph>
            </Button>

            <ParagraphSmall style={{ textAlign: 'left', marginBottom: '0' }}>
              W ramach szkolenia otrzymujesz:
            </ParagraphSmall>
            <ul style={{ textAlign: 'left' }}>
              <li>1 dzień intensywnych szkoleń i interaktywnych warsztatów</li>
              <li>kompetencje dla życia z lekkością i radością w obliczu dynamicznych zmian</li>
              <li>workbook w PDF pełen wskazówek, zadań i ćwiczeń </li>
              <li>przerwy kawowe i networking </li>
              <li>certyfikat ukończenia szkolenia</li>{' '}
              <li> dostęp do webinaru podsumowującego do 48 godzin po szkoleniu.</li>
            </ul>
            <ParagraphSmall style={{ textAlign: 'left', fontWeight: '800' }}>
              Cena warsztatu szkoleniowego: 179 zł do 18.08. 2021 później 199 zł
            </ParagraphSmall>
            <Button onClick={handleShopLink}>
              <ButtonParagraph>Kup teraz</ButtonParagraph>
            </Button>
          </BottomWrapper>
        </ConsultWrapper>
      </ScrollTemplate>
    </>
  );
};

export default Consultation;
